<template>
  <div style="height: 100%">
    <el-container style="height: 100%">
      <el-header style="padding: 0" height="50px" class="elHeader">
        <template>
          <div class="headerLogo" :style="isShow?'width:220px':'width:54px'">
            <img src="../..//assets/logo.png" alt="">
            <div>电力系统后台管理</div>
          </div>
          <i :class="isShow?'el-icon-s-fold':'el-icon-s-unfold'" class="headerIcom" @click='changeStatus'></i>
          <el-menu :default-active="$route.matched[1].path" background-color="#304156" text-color="#bfcbd9"
                   active-text-color="#409eff" mode="horizontal" router>
            <el-menu-item class="headerMenu" v-for="(item, index) in menu" :key="index" :index="item.path" v-show="item.show">
              <i :class="icon[index]"></i>
              <div>{{ item.title }}</div>
            </el-menu-item>
          </el-menu>
          <div class="rightIcom">
            <el-dropdown @command="handleCommand">
              <i style="color: white;" class="el-icon-caret-bottom"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="0">退出</el-dropdown-item>
                <el-dropdown-item :command="1">修改密码</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-header>
      <el-main class="elMain">
        <router-view :isShow="isShow"></router-view>
      </el-main>
    </el-container>
    <el-dialog title="修改密码" :visible.sync="restPasswordVisible">
      <el-form :model="restPasswordForm" :rules="rules" ref="restPasswordForm">
        <el-form-item label="账号" label-width="120px" prop="code">
          <el-input v-model="restPasswordForm.code" :disabled="true" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="原密码" label-width="120px" prop="note">
          <el-input v-model="restPasswordForm.note" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" label-width="120px" prop="password">
          <el-input v-model="restPasswordForm.password" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="restPasswordVisible = false">取 消</el-button>
        <el-button type="primary" @click="resetPassword">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      restPasswordVisible: false,
      restPasswordForm: {
        code: '',
        password: '',
        note: ''
      },
      rules: {
        code: [
          { required: true, message: '请输入账号' }
        ],
        password: [
          { required: true, message: '请输入新密码' }
        ],
        note: [
          { required: true, message: '请输入原密码' }
        ]
      },
      isShow: true, // 顶部logo和侧边栏宽度显示
      icon: ['el-icon-document', 'el-icon-help', 'el-icon-s-operation', 'el-icon-setting'] // 顶部菜单图标
    }
  },
  mounted () {
  },
  computed: {
    menu () {
      const level = Number(window.localStorage.getItem('level'))
      const menu = this.$router.options.routes[0].children // 返回一级路由 用于遍历显示顶部菜单
      for (let i = 0; i < menu.length; i++) {
        const pathStart = menu[i].path.substr(0, 3)
        if (pathStart === '/0/') {
          menu[i].show = (level === 0)
        } else if (pathStart === '/1/') {
          menu[i].show = ((level === 0) || (level === 1))
        } else {
          menu[i].show = true
        }
      }
      return menu
    }
  },
  methods: {
    changeStatus () { // 侧边栏宽度显示切换
      this.isShow = !this.isShow
    },
    // 头部右侧下拉菜单点击监听
    handleCommand (command) {
      if (!command) {
        this.logout()
      } else if (command === 1) {
        this.handleResetPassword()
      }
    },
    // 退出登录点击监听
    logout () {
      this.$api.logout().then(({ data: res }) => {
        if (!res.code) {
          this.$message({
            type: 'err',
            message: res.msg
          })
        }
      })
      // localStorage.removeItem('token')// 清空缓存
      // this.$router.push('/login') // 路由跳转至login
    },
    // 设备管理跳转
    handleResetPassword () {
      this.restPasswordForm.code = window.localStorage.getItem('code')
      this.restPasswordForm.password = ''
      this.restPasswordForm.note = ''
      this.restPasswordVisible = true
    },
    resetPassword () {
      this.$refs.restPasswordForm.validate((valid) => {
        if (valid) {
          this.$api.resetPassword(this.restPasswordForm).then(({ data: res }) => {
            if (!res.code) {
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.restPasswordVisible = false
            } else {
              this.$message({
                type: 'err',
                message: res.msg
              })
            }
          })
        }
      })
    }
  }
}

</script>

<style>
.elHeader {
  background: #304056;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}

.headerLogo {
  width: 220px;
  height: 50px;
  background: #2b2f3b;
  display: flex;
  align-items: center;
  /*color: white;*/
  overflow: hidden;
  font-size: 15px;
  transition: width 0.3s;
  -webkit-transition: width 0.3s;
}

.headerLogo img {
  width: 30px;
  height: 30px;
  margin: 0 12px;
}

.headerLogo div {
  width: 170px;
  text-align: center;
  white-space: nowrap;
  font-family: 楷体, simsun;
}

.headerIcom {
  font-size: 25px;
  margin: 0 20px;
}

.headerMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px !important;
}

.elMain {
  width: 100%;
  padding: 0;
}

.rightIcom {
  position: absolute;
  right: 0;
  margin: 0 20px;
}
</style>
