import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login/Login'
import Index from '../views/Index/Index'
import DeviceManagement from '../pages/DeviceManagement/DeviceManagement'
import UserManagement from '../pages/UserManagement/UserManagement'

Vue.use(VueRouter)

const routes = [
  // 常用页面路由
  // 若要添加页面，不需要在header以及aside组件中添加，只需在路由中配置即可
  // 若配置了子路由必须要在父路由中重定向 否则会报错
  {
    name: 'Index',
    path: '/index',
    component: Index,
    redirect: '/DeviceManagement',
    children: [{
      title: '设备',
      path: '/DeviceManagement',
      component: DeviceManagement
    }, {
      title: '账户',
      path: '/1/UserManagement',
      component: UserManagement
    }]
  },
  // 不常用路由
  {
    name: 'Login',
    path: '/_/login',
    component: Login
  }, {
    path: '/',
    redirect: '/index'
  }
]

const router = new VueRouter({
  routes
})

/* 挂载路由导航守卫 */
router.beforeEach((to, from, next) => {
  /* to 将要访问的路径
  from 代表从哪个路径跳转而来
  next 代表放行
  next() 放行 next('/login') 强制跳转 */
  const pathStart = to.path.substr(0, 3)
  console.log(pathStart)
  console.log(to.path)
  if (pathStart === '/_/') return next()
  /* 获取token */
  const tokenStr = window.localStorage.getItem('token')
  console.log(tokenStr)
  if (!tokenStr) return next('_/login')
  const level = Number(window.localStorage.getItem('level'))
  if (level === 0) {
    next()
  } else if (level === 1) {
    if (pathStart === '/0/') return next('_/login')
    next()
  } else if (level === 2) {
    if ((pathStart === '/0/') || (pathStart === '/1/')) return next('_/login')
    next()
  } else {
    next('/_/login')
  }
})

export default router
