// Vuex store
import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)
// 用于响应组件中的动作
const actions = {

}
// mutations--用于操作数据
const mutations = {
  updateToken (state, data) {
    state.token = data
  }
}
// state--用于存储数据
const state = {
  token: '1324'
}

// 创建并暴露store
export default new Vuex.Store({
  actions,
  mutations,
  state
})
