<template>
  <div style="width: 100%; height: 100%; overflow: hidden">
    <div class="pageTitle">账户</div>
    <div class="page">
      <div class="search">
        <div class="searchParam">
          <div>账号</div>
          <div class="searchInput">
            <el-input v-model="code" placeholder=""></el-input>
          </div>
        </div>
        <div class="searchParam">
          <div>名称</div>
          <div class="searchInput">
            <el-input v-model="name" placeholder=""></el-input>
          </div>
        </div>
        <el-button class="searchButton" @click="getChildUsers(1)">查询</el-button>
        <el-button class="searchButton" @click="dialogFormVisible = true">新增</el-button>
        <!--添加用户弹窗-->
        <el-dialog title="新增账户" :visible.sync="dialogFormVisible" @close="addChildUserClose">
          <el-form :model="addChildUserForm" :rules="rules" ref="addChildUserForm">
            <el-form-item label="账号" label-width="120px" prop="code">
              <el-input v-model="addChildUserForm.code" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="名称" label-width="120px" prop="name">
              <el-input v-model="addChildUserForm.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" label-width="120px" prop="password">
              <el-input v-model="addChildUserForm.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="类型" label-width="120px" prop="level" v-if="loginLevel===0">
              <el-select v-model="addChildUserForm.level" placeholder="请选择级别">
                <el-option label="代理商" value="1"></el-option>
                <el-option label="普通用户" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" label-width="120px" prop="note">
              <el-input v-model="addChildUserForm.note" type="textarea" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="addChildUser">确 定</el-button>
          </div>
        </el-dialog>
        <!--   修改用户弹窗     -->
        <el-dialog title="修改账户" :visible.sync="modifyFormVisible">
          <el-form :model="modifyChildUserForm" :rules="rules" ref="modifyChildUserForm">
            <el-form-item label="账号" label-width="120px" prop="code">
              <el-input v-model="modifyChildUserForm.code" :disabled="true" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="名称" label-width="120px" prop="name">
              <el-input v-model="modifyChildUserForm.name" autocomplete="off"></el-input>
            </el-form-item>
<!--            暂不支持等级修改-->
<!--            <el-form-item label="类型" label-width="120px" prop="level" v-if="loginLevel===0">-->
<!--              <el-select v-model="modifyChildUserForm.level" placeholder="请选择级别">-->
<!--                <el-option label="代理商" value="1"></el-option>-->
<!--                <el-option label="普通用户" value="2"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
            <el-form-item label="备注" label-width="120px" prop="note">
              <el-input v-model="modifyChildUserForm.note" type="textarea" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="modifyFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="modifyChildUser">确 定</el-button>
          </div>
        </el-dialog>
        <!--重置密码弹窗-->
        <el-dialog title="重置密码" :visible.sync="restFormVisible">
          <el-form :model="restPasswordForm" :rules="rules" ref="restPasswordForm">
            <el-form-item label="账号" label-width="120px" prop="code">
              <el-input v-model="restPasswordForm.code" :disabled="true" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" label-width="120px" prop="password">
              <el-input v-model="restPasswordForm.password" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="restFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="restPwd">确 定</el-button>
          </div>
        </el-dialog>
        <!--设备弹窗-->
        <el-dialog :title="'账号'+deviceCode+' 设备管理'" :visible.sync="deviceVisible" :fullscreen="true">
          <el-row>
            <el-col :span="11">
              <div>
                <div style="font-weight:bold">未拥有设备</div>
                <div class="search">
                  <div class="searchParam">
                    <div>编号</div>
                    <div class="searchInput">
                      <el-input v-model="deviceWithoutSn" size="small" placeholder="请输入内容"></el-input>
                    </div>
                  </div>
                  <div class="searchParam">
                    <div>名称</div>
                    <div class="searchInput">
                      <el-input v-model="deviceWithoutName" size="small" placeholder="请输入内容"></el-input>
                    </div>
                  </div>
                  <el-button class="searchButton" @click="getDevicesWithout(1)" size="small">查询</el-button>
                </div>
                <el-table
                  :data="devicesWithout"
                  height="500px"
                  size="small"
                  border
                  stripe
                  @selection-change="devicesWithoutSelectionChange"
                  style="width: 100%; margin-top: 20px">
                  <el-table-column
                    type="selection">
                  </el-table-column>
                  <el-table-column
                    prop="sn"
                    label="编号">
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    label="名称">
                  </el-table-column>
                </el-table>
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="devicesTotalWithout"
                  @current-change="deviceWithoutCurrentChange"
                  style="margin-top: 10px; text-align: center">
                </el-pagination>
              </div>
            </el-col>
            <el-col :span="2">
              <div style="text-align: center; padding-top: 200px">
                <div style="padding: 10px">
                  <el-button type="success" icon="el-icon-d-arrow-right" @click="pushDevices">添加</el-button>
                </div>
                <div style="padding: 10px">
                  <el-button type="danger" icon="el-icon-d-arrow-left" @click="pullDevices">移除</el-button>
                </div>
              </div>
            </el-col>
            <el-col :span="11">
              <div>
                <div style="font-weight:bold">已拥有设备</div>
                <div class="search">
                  <div class="searchParam">
                    <div>编号</div>
                    <div class="searchInput">
                      <el-input v-model="deviceSn" size="small" placeholder="请输入内容"></el-input>
                    </div>
                  </div>
                  <div class="searchParam">
                    <div>名称</div>
                    <div class="searchInput">
                      <el-input v-model="deviceName" size="small" placeholder="请输入内容"></el-input>
                    </div>
                  </div>
                  <el-button class="searchButton" @click="getDevices(1)" size="small">查询</el-button>
                </div>
                <el-table
                  :data="devices"
                  height="500px"
                  size="small"
                  border
                  stripe
                  @selection-change="devicesSelectionChange"
                  style="width: 100%; margin-top: 20px">
                  <el-table-column
                  type="selection">
                  </el-table-column>
                  <el-table-column
                    prop="sn"
                    label="编号">
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    label="名称">
                  </el-table-column>
                </el-table>
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="devicesTotal"
                  @current-change="deviceCurrentChange"
                  style="margin-top: 10px; text-align: center">
                </el-pagination>
              </div>
            </el-col>
          </el-row>
        </el-dialog>
      </div>
      <!--   账户列表   -->
      <el-table
        :data="tableData"
        height="71%"
        size="small"
        stripe
        border
        style="width: 100%; margin-top: 10px">
        <el-table-column
          prop="code"
          label="账号">
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称">
        </el-table-column>
        <el-table-column v-if="loginLevel===0"
          prop="level"
          label="类型"
          :formatter="formatter"
        >
        </el-table-column>
        <el-table-column
          prop="note"
          label="备注">
        </el-table-column>
        <el-table-column label="操作" width="330px">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">修改
            </el-button>
            <el-button
              size="mini"
              @click="handleResetPwd(scope.$index, scope.row)">重置密码</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            <el-button
              size="mini"
              type="primary"
              @click="handleDevice(scope.$index, scope.row)">管理设备</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="currentChange"
        style="margin-top: 5px; text-align: center">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserManagement',
  data () {
    return {
      loginLevel: 2,
      // 查询子用户参数
      level: 2,
      code: '',
      name: '',
      // 子用户列表数据
      tableData: [],
      num: 1,
      total: 0,
      // 新增设用户单验证
      dialogFormVisible: false,
      addChildUserForm: {
        code: '',
        password: '',
        name: '',
        level: '',
        parent: '',
        note: ''
      },
      rules: {
        code: [
          { required: true, message: '请输入账号' }
        ],
        name: [
          { required: true, message: '请输入名称' }
        ],
        password: [
          { required: true, message: '请输入密码' }
        ],
        level: [
          { required: true, message: '请选择级别' }
        ]
      },
      modifyFormVisible: false,
      modifyChildUserForm: {
        code: '',
        name: '',
        level: '',
        parent: '',
        note: ''
      },
      restFormVisible: false,
      restPasswordForm: {
        code: '',
        password: ''
      },
      deviceVisible: false,
      deviceCode: '',
      deviceWithoutSn: '',
      deviceWithoutName: '',
      devicesWithout: [],
      devicesWithoutSelection: [],
      devicesTotalWithout: 0,
      devicesNumWithout: 1,
      deviceSn: '',
      deviceName: '',
      devices: [],
      devicesSelection: [],
      devicesTotal: 0
    }
  },
  mounted () {
    this.loginLevel = Number(window.localStorage.getItem('level'))
    this.getChildUsers(1)
  },
  methods: {
    formatter (row, column) {
      const level = Number(row.level)
      if (level === 0) {
        return '管理员'
      } else if (level === 1) {
        return '代理商'
      } else if (level === 2) {
        return '普通用户'
      } else {
        return ''
      }
    },
    // 添加设备表单复位
    addChildUserClose () {
      this.$refs.addChildUserForm.resetFields()
    },
    // 添加子用户确认按钮点击监听
    addChildUser () {
      console.log('AAA', this.addChildUserForm)
      if (this.loginLevel !== 0) {
        this.addChildUserForm.level = 2
      }
      this.addChildUserForm.parent = window.localStorage.getItem('code')
      this.$refs.addChildUserForm.validate((valid) => {
        if (valid) {
          this.$api.addChildUser(this.addChildUserForm).then(({ data: res }) => {
            if (!res.code) {
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.dialogFormVisible = false
              this.getChildUsers(1)
            } else {
              this.$message({
                type: 'err',
                message: res.msg
              })
            }
          })
        }
      })
    },
    // 查询子用户
    getChildUsers (num) {
      const params = {
        code: this.code,
        name: this.name,
        num: num
      }
      this.$api.childUser(params).then(({ data: res }) => {
        if (!res.code) {
          this.tableData = res.data.list
          console.log(this.tableData)
          this.total = res.data.total
        } else {
          this.$message({
            type: 'err',
            message: res.msg
          })
        }
      })
    },
    // 分页点击监听
    currentChange (index) {
      this.num = index
      this.getChildUsers(this.num)
    },
    // 修改设备
    modifyChildUser () {
      this.modifyChildUserForm.parent = window.localStorage.getItem('code')
      this.modifyChildUserForm.level = null
      this.$refs.modifyChildUserForm.validate((valid) => {
        if (valid) {
          this.$api.editChildUser(this.modifyChildUserForm).then(({ data: res }) => {
            if (!res.code) {
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.modifyFormVisible = false
              this.getChildUsers(this.num)
            } else {
              this.$message({
                type: 'err',
                message: res.msg
              })
            }
          })
        }
      })
    },
    // 修改设备
    handleEdit (index, row) {
      this.modifyChildUserForm.code = row.code
      this.modifyChildUserForm.name = row.name
      const level = Number(row.level)
      if (level === 0) {
        this.modifyChildUserForm.level = '管理员'
      } else if (level === 1) {
        this.modifyChildUserForm.level = '代理商'
      } else if (level === 0) {
        this.modifyChildUserForm.level = '普通用户'
      } else {
        this.modifyChildUserForm.level = ''
      }
      this.modifyChildUserForm.note = row.note
      this.modifyFormVisible = true
    },
    // 重置密码
    restPwd () {
      this.restPasswordForm.parent = window.localStorage.getItem('code')
      this.$refs.restPasswordForm.validate((valid) => {
        if (valid) {
          this.$api.editChildUser(this.restPasswordForm).then(({ data: res }) => {
            if (!res.code) {
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.restFormVisible = false
              this.getChildUsers(this.num)
            } else {
              this.$message({
                type: 'err',
                message: res.msg
              })
            }
          })
        }
      })
    },
    // 修改设备
    handleResetPwd (index, row) {
      this.restPasswordForm.code = row.code
      this.restPasswordForm.password = row.password
      this.restFormVisible = true
    },
    // 删除设备
    handleDelete (index, row) {
      this.$confirm('是否删除 账号:' + row.code + '， 名称：' + row.name)
        .then(_ => {
          this.$api.deleteChildUser({
            code: row.code
          }).then(({ data: res }) => {
            if (!res.code) {
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.getChildUsers(this.num)
            } else {
              this.$message({
                type: 'err',
                message: res.msg
              })
            }
          })
        })
        .catch(_ => {})
    },
    handleDevice (index, row) {
      this.deviceCode = row.code
      const params = {
        code: this.deviceCode
      }
      this.$api.devices(params).then(({ data: res }) => {
        if (!res.code) {
          this.devices = res.data.list
          this.devicesTotal = res.data.total
        } else {
          this.$message({
            type: 'err',
            message: res.msg
          })
        }
      })
      this.$api.devicesWithout(params).then(({ data: res }) => {
        if (!res.code) {
          this.devicesWithout = res.data.list
          this.devicesTotalWithout = res.data.total
        } else {
          this.$message({
            type: 'err',
            message: res.msg
          })
        }
      })
      this.deviceVisible = true
    },
    getDevicesWithout (num) {
      const params = {
        code: this.deviceCode,
        sn: this.deviceWithoutSn,
        name: this.deviceWithoutName,
        num: num
      }
      this.$api.devicesWithout(params).then(({ data: res }) => {
        if (!res.code) {
          this.devicesWithout = res.data.list
          this.devicesTotalWithout = res.data.total
        } else {
          this.$message({
            type: 'err',
            message: res.msg
          })
        }
      })
    },
    // 分页点击监听
    deviceWithoutCurrentChange (index) {
      this.getDevicesWithout(index)
    },
    devicesWithoutSelectionChange (val) {
      this.devicesWithoutSelection = val
    },
    getDevices (num) {
      const params = {
        code: this.deviceCode,
        sn: this.deviceSn,
        name: this.deviceName,
        num: num
      }
      this.$api.devices(params).then(({ data: res }) => {
        if (!res.code) {
          this.devices = res.data.list
          this.devicesTotal = res.data.total
        } else {
          this.$message({
            type: 'err',
            message: res.msg
          })
        }
      })
    },
    // 分页点击监听
    deviceCurrentChange (index) {
      this.getDevices(index)
    },
    devicesSelectionChange (val) {
      this.devicesSelection = val
    },
    pushDevices () {
      const sns = []
      for (let i = 0; i < this.devicesWithoutSelection.length; i++) {
        sns[i] = this.devicesWithoutSelection[i].sn
      }
      const params = {
        code: this.deviceCode,
        sns: sns
      }
      this.$api.pushDevices(params).then(({ data: msg }) => {
        if (!msg.code) {
          this.$message({
            type: 'success',
            message: msg.msg
          })
          this.getDevices(1)
          this.getDevicesWithout(1)
        } else {
          this.$message({
            type: 'err',
            message: msg.msg
          })
        }
      })
    },
    pullDevices () {
      const sns = []
      for (let i = 0; i < this.devicesSelection.length; i++) {
        sns[i] = this.devicesSelection[i].sn
      }
      const params = {
        code: this.deviceCode,
        sns: sns
      }
      this.$api.pullDevices(params).then(({ data: msg }) => {
        if (!msg.code) {
          this.$message({
            type: 'success',
            message: msg.msg
          })
          this.getDevices(1)
          this.getDevicesWithout(1)
        } else {
          this.$message({
            type: 'err',
            message: msg.msg
          })
        }
      })
    }
  }
}
</script>

<style>
.pageTitle {
  font-size: 25px;
  margin: 10px 0 10px 2.5%;
}

.page {
  width: 95%;
  height: 100%;
  margin: 0 2.5%;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}

.search {
  width: 100%;
  display: flex;
  margin-top: 20px;
}

.searchParam {
  width: 30%;
  display: flex;
  align-items: center;
}

.searchInput {
  width: 80%;
  margin-left: 10px;
}

.searchButton {
  margin-left: 10%;
}
</style>
