// 引入axios
import axios from 'axios'
import route from '../../router/index'

// axios.defaults.baseURL = 'https://api.faniot.cn'
// axios.defaults.baseURL = 'http://localhost:9902'
axios.defaults.baseURL = 'https://api.jsttpower.cn'
// 设置默认请求头
axios.defaults.headers = {
  satoken: window.localStorage.getItem('token')
}
axios.defaults.timeout = 10000

axios.interceptors.response.use(
  response => {
    if ((response.data.code === 401) || (response.data.msg === '登出成功')) {
      route.replace({ path: '/_/login' })
    }
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export default {
  // get请求
  get (url, param, dn) {
    if (!dn) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url,
          params: param
        }).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    } else {
      return new Promise((resolve, reject) => {
        axios({
          method: 'GET',
          responseType: 'arraybuffer',
          url,
          params: param
        }).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  // post请求
  post (url, param, dn) {
    if (!dn) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url,
          data: param
        }).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    } else {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          responseType: 'arraybuffer',
          url,
          data: param
        }).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    }
  }
}
