<template>
  <div>
    <div class="loginBody">
      <div class="loginBorder">
        <div class="loginName">登录</div>
        <div class="loginInput">
          <el-input
            placeholder="请输入账号"
            v-model="userName"
            clearable>
          </el-input>
          <el-input
            placeholder="请输入密码"
            v-model="password"
            show-password>
          </el-input>
          <div class="captcha">
            <el-input
              class="captchaInput"
              placeholder="验证码"
              v-model="captchaValue"
              >
            </el-input>
            <img class="captchaImage" :src="captchaImg" @click="getCaptcha" />
          </div>
        </div>
<!--        <div class="loginrem">-->
<!--          <el-checkbox v-model="checked">免登录</el-checkbox>-->
<!--          <div><a href="#">忘记密码?</a></div>-->
<!--        </div>-->
        <div class="loginButton">
          <el-button type="primary" style="width: 100%" @click="login">登录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Login',
  data () {
    return {
      captchaImg: '',
      userName: '',
      password: '',
      captchaValue: '',
      key: ''
    }
  },

  beforeMount () {
    this.getCaptcha()
  },

  mounted () {
    // this.$store.commit('updateToken', 15648444256)
    // console.log(this.$store.state.token)
    // window.localStorage.setItem('token', '123456')
  },
  methods: {
    getCaptcha () {
      this.$api.captcha().then((res) => {
        this.captchaImg = res.data.data.captcha
        this.key = res.data.data.key
      })
    },
    async login () {
      const params = {
        code: this.userName,
        password: this.password,
        key: this.key,
        captcha: this.captchaValue
      }
      await this.$api.login(params).then(({ data: res }) => {
        if (!res.code) {
          window.localStorage.setItem('token', res.data.access_token)
          window.localStorage.setItem('level', res.data.level)
          window.localStorage.setItem('code', this.userName)
          axios.defaults.headers = {
            satoken: window.localStorage.getItem('token')
          }
          this.$router.push('/index')
        } else {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('level', -1)
          window.localStorage.setItem('code', '')
          axios.defaults.headers = {
            satoken: ''
          }
          this.$message({
            type: 'err',
            message: res.msg
          })
        }
      })
    }
  }
}
</script>

<style>
.loginBody {
  display: flex;
  justify-content: flex-end;
}

.loginBorder {
  width: 400px;
  height: 540px;
  border: 1px solid #d4d4d4;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 12%;
  right: 25%;
  /*top: 50%;*/
  /*transform: translateY(-50%);*/
}

.loginName {
  padding: 40px 0 0;
  color: #333;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-weight: bold;
}

.loginInput {
  width: 85%;
  margin: 20px 0;
}

.loginInput .el-input__inner {
  height: 46px;
  margin: 10px 0;
}

.captcha{
  display: flex;
  height: 65px;
  align-items: center;
}

.captchaInput{
  width: 50%;
}

.captchaImage{
  width: 40%;
  margin-left: 10%;
  height: 46px;
}

.loginrem {
  width: 85%;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: space-between;
}

.loginButton {
  width: 85%;
  margin-top: 20px;
}
</style>
