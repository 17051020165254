<template>
  <div style="width: 100%; height: 100%; overflow: hidden">
    <div class="pageTitle">设备</div>
    <div class="page">
      <div class="search">

        <div class="searchParam">
          <div>产品</div>
          <div class="searchInput">
            <el-select v-model="products.name" placeholder="">
              <el-option label="" value=""></el-option>
              <el-option v-for="(item, index) in products.names" :key="index" :value="item"></el-option>
            </el-select>
          </div>
        </div>
        <div class="searchParam">
          <div>类型</div>
          <div class="searchInput">
            <el-select v-model="products.form" placeholder="">
              <el-option label="" value=""></el-option>
              <el-option v-for="(item, index) in products.forms" :key="index" :value="item.substring(2)"></el-option>
            </el-select>
          </div>
        </div>
        <div class="searchParam">
          <div>编号</div>
          <div class="searchInput">
            <el-input v-model="sn" placeholder=""></el-input>
          </div>
        </div>
        <div class="searchParam">
          <div>名称</div>
          <div class="searchInput">
            <el-input v-model="name" placeholder=""></el-input>
          </div>
        </div>
        <el-button class="searchButton" @click="getDevices">查询</el-button>
        <el-button class="searchButton" @click="dnDevices">导出</el-button>
        <el-button class="searchButton" @click="dialogFormVisible = true" v-if="level===0">新增</el-button>
        <div id="qrcode" v-show="false">
          <vue-qr ref="qr" :text="qrText" :size="50" :margin="0" :dotScale="1" :callback="test" :qid="qrText"></vue-qr>
        </div>
        <!--添加设备弹窗-->
        <el-dialog title="新增设备" :visible.sync="dialogFormVisible" @close="addDeviceClose">
          <el-form :model="addDeviceForm" :rules="rules" ref="addDeviceForm">
            <el-form-item label="产品" label-width="120px" prop="product">
              <el-select v-model="products.addName" placeholder="请选择产品">
                <el-option v-for="(item, index) in products.names" :key="index" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="products.addForms.length > 1" label="类型" label-width="120px" prop="form">
              <el-select v-model="products.addForm" placeholder="请选择类型">
                <el-option v-for="(item, index) in products.addForms" :key="index" :label="item.substring(2)" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="products.addForm&&products.addForm.charAt(0)!=='A'" label="编号" label-width="120px" prop="sn">
              <el-input v-model="addDeviceFormSn" :disabled="false" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="名称" label-width="120px" prop="name">
              <el-input v-model="addDeviceForm.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="备注" label-width="120px" prop="note">
              <el-input v-model="addDeviceForm.note" type="textarea" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="addDevice">确 定</el-button>
          </div>
        </el-dialog>
        <!--   修改设备弹窗     -->
        <el-dialog title="修改设备" :visible.sync="modifyFormVisible">
          <el-form :model="modifyDeviceForm" :rules="rules" ref="modifyDeviceForm">
            <el-form-item label="编号" label-width="120px" prop="sn">
              <el-input v-model="modifyDeviceForm.sn" :disabled="true" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="名称" label-width="120px" prop="name">
              <el-input v-model="modifyDeviceForm.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="备注" label-width="120px" prop="note">
              <el-input v-model="modifyDeviceForm.note" type="textarea" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="modifyFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="modifyDevice">确 定</el-button>
          </div>
        </el-dialog>
      </div>
      <!--   设备列表   -->
      <el-table
        :data="tableData"
        height="71%"
        size="small"
        stripe
        border
        style="width: 100%; margin-top: 10px">
        <el-table-column
          prop="sn"
          label="编号">
        </el-table-column>
<!--       备注 -->
        <el-table-column
          prop="note"
          label="用户">
        </el-table-column>
<!--       名称 -->
        <el-table-column
          prop="name"
          label="位置">
        </el-table-column>
<!--        <el-table-column-->
<!--          prop="product"-->
<!--          label="产品">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          prop="form"-->
<!--          label="类型">-->
<!--        </el-table-column>-->

        <el-table-column label="操作" v-if="level===0" width="200px">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">修改
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除
            </el-button>
<!--            <el-button-->
<!--              size="mini"-->
<!--              type="primary"-->
<!--              v-if="level===0"-->
<!--              @click="saveQrCode(scope.$index, scope.row)">二维码</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="currentChange"
        style="margin-top: 5px; text-align: center;">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr'

export default {
  name: 'DeviceManagement',
  components: {
    vueQr
  },
  data () {
    return {
      products: {
        with: '', // 拥有的产品, 查询活动
        names: '', // 产品名称列表
        name: '', // 选中的产品名称
        forms: '', // 类型列表
        form: '', // 选中类型
        addName: '',
        addForms: [],
        addForm: ''
      },
      snAuto: true,
      level: 2,
      // 查询用户设备参数
      sn: '',
      name: '',
      qrText: '',
      num: 1,
      // 用户设备列表数据
      tableData: [],
      total: 0,
      // 新增设备表单验证
      dialogFormVisible: false,
      addDeviceForm: {
        sn: '',
        name: '',
        product: '',
        form: '',
        note: ''
      },
      addDeviceFormSn: '',
      rules: {
        // sn: [
        //   { required: true, message: '请输入编号' }
        // ],
        // name: [
        //   { required: true, message: '请输入名称' }
        // ],
        // product: [
        //   { required: true, message: '请选择产品' }
        // ],
        // form: [
        //   { required: true, message: '请选择类型' }
        // ]
      },
      modifyFormVisible: false,
      modifyDeviceForm: {
        sn: '',
        name: '',
        note: ''
      }
    }
  },
  mounted () {
    this.level = Number(window.localStorage.getItem('level'))
    this.getDevices()
    this.getProducts()
  },
  watch: {
    'products.name': function (val) {
      if (val === '') {
        this.products.forms = [].concat.apply([], Object.values(this.products.with))
      } else {
        this.products.forms = this.products.with[val]
      }
      if (!this.products.forms.find(item => item === this.products.form)) {
        this.products.form = ''
      }
    },
    'products.addName': function (val) {
      if (val === '') {
        this.products.addForms = []
        this.products.addForm = null
      } else {
        this.products.addForms = this.products.with[val]
        if (this.products.addForms.length === 1) {
          this.products.addForm = this.products.addForms[0]
        } else {
          this.products.addForm = null
        }
      }
    },
    'products.with': function (val) {
      this.products.names = Object.keys(val)
      if (!this.products.names.find(item => item === this.products.name)) {
        this.products.name = ''
      }

      this.products.forms = [].concat.apply([], Object.values(this.products.with))
      if (!this.products.forms.find(item => item === this.products.form)) {
        this.products.form = ''
      }
    }
  },
  methods: {
    test (dataUrl, id) {
      if (id.length === 0) {
        return
      }

      const a = document.createElement('a')
      a.href = dataUrl
      a.setAttribute('download', id)
      a.click()
      this.qrText = ''
    },
    // 添加设备表单复位
    addDeviceClose () {
      this.$refs.addDeviceForm.resetFields()
      this.products.addName = ''
      this.addDeviceFormSn = ''
    },
    // 添加设备确认按钮点击监听
    addDevice () {
      if (this.products.addForm.charAt(0) === 'A') {
        const date = new Date()
        const yy = date.getFullYear().toString().substr(2, 2)
        const month = date.getMonth() + 1
        const MM = month < 10 ? '0' + month.toString() : month.toString()
        const day = date.getDate()
        const dd = day < 10 ? '0' + day.toString() : day.toString()
        const hour = date.getHours()
        const hh = hour < 10 ? '0' + hour.toString() : hour.toString()
        const minute = date.getMinutes()
        const mm = minute < 10 ? '0' + minute.toString() : minute.toString()
        const second = date.getSeconds()
        const ss = second < 10 ? '0' + second.toString() : second.toString()
        const ms = date.getMilliseconds()
        const SSS = ms < 10 ? '00' + ms.toString() : (ms < 100 ? '0' + ms.toString() : ms.toString())
        if (this.products.addForm.charAt(1) !== '-') {
          this.addDeviceForm.sn = this.products.addForm.charAt(1) + yy + MM + dd + hh + mm + ss + SSS
        } else {
          this.addDeviceForm.sn = yy + MM + dd + hh + mm + ss + SSS
        }
      } else {
        if (this.products.addForm.charAt(1) !== '-') {
          this.addDeviceForm.sn = this.products.addForm.charAt(1) + this.addDeviceFormSn
        } else {
          this.addDeviceForm.sn = this.addDeviceFormSn
        }
      }

      this.addDeviceForm.product = this.products.addName
      this.addDeviceForm.form = this.products.addForm.substring(2)

      this.$refs.addDeviceForm.validate((valid) => {
        if (valid) {
          this.$api.addDevices(this.addDeviceForm).then(({ data: res }) => {
            if (!res.code) {
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.dialogFormVisible = false
              this.getDevices()
            } else {
              this.$message({
                type: 'err',
                message: res.msg
              })
            }
          })
        }
      })
      // if (this.addDeviceForm.type) {
      //   const date = new Date()
      //   const yy = date.getFullYear().toString().substr(2, 2)
      //   const month = date.getMonth() + 1
      //   const MM = month < 10 ? '0' + month.toString() : month.toString()
      //   const day = date.getDate()
      //   const dd = day < 10 ? '0' + day.toString() : day.toString()
      //   const hour = date.getHours()
      //   const hh = hour < 10 ? '0' + hour.toString() : hour.toString()
      //   const minute = date.getMinutes()
      //   const mm = minute < 10 ? '0' + minute.toString() : minute.toString()
      //   const second = date.getSeconds()
      //   const ss = second < 10 ? '0' + second.toString() : second.toString()
      //   const ms = date.getMilliseconds()
      //   const SSS = ms < 10 ? '00' + ms.toString() : (ms < 100 ? '0' + ms.toString() : ms.toString())
      //   this.addDeviceForm.sn = this.addDeviceForm.type + yy + MM + dd + hh + mm + ss + SSS
      //
      //   const s = new Date().toString()
      //   console.log(s)
      //   this.$refs.addDeviceForm.validate((valid) => {
      //     if (valid) {
      //       this.$api.addDevices(this.addDeviceForm).then(({ data: res }) => {
      //         if (!res.code) {
      //           this.$message({
      //             type: 'success',
      //             message: res.msg
      //           })
      //           this.dialogFormVisible = false
      //           this.getDevices()
      //         } else {
      //           this.$message({
      //             type: 'err',
      //             message: res.msg
      //           })
      //         }
      //       })
      //     }
      //   })
      // }
    },

    // 请求用户设备
    getProducts () {
      const params = {
        code: window.localStorage.getItem('code')
      }
      this.$api.productWith(params).then(({ data: res }) => {
        if (!res.code) {
          console.log('产品', res)
          this.products.with = res.data
          console.log(this.products.with)
        } else {
          this.$message({
            type: 'err',
            message: res.msg
          })
        }
      })
    },
    // 请求用户设备
    getDevices () {
      const params = {
        code: window.localStorage.getItem('code'),
        sn: this.sn,
        name: this.name,
        num: this.num,
        product: this.products.name,
        form: this.products.form
      }
      this.$api.devices(params).then(({ data: res }) => {
        if (!res.code) {
          this.tableData = res.data.list
          this.total = res.data.total
        } else {
          this.$message({
            type: 'err',
            message: res.msg
          })
        }
      })
    },

    dnDevices () {
      const params = {
        code: window.localStorage.getItem('code'),
        sn: this.sn,
        name: this.name,
        num: this.num,
        product: this.products.name,
        form: this.products.form,
        dn: true
      }
      this.$api.devices(params, true).then((res) => {
        if (res.headers['content-type'] === 'application/json') {
          const blob = new Blob([res.data], {
            type: 'application/json'
          })

          const reader = new FileReader() // 创建一个FileReader实例
          reader.readAsText(blob, 'utf-8') // 读取文件,结果用字符串形式表示
          reader.onload = () => { // 读取完成后,**获取reader.result**
            this.$message({
              type: 'err',
              message: JSON.parse(reader.result).msg
            })
          }
          return
        }
        // excel 的 MIME 格式为 application/vnd.ms-excel
        const blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel'
        })
        // 创建a链接 href链接地址 download为下载下来后文件的名称
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'aaa.xls'
        link.style.display = 'none' // 隐藏a标签 直接调用a标签的点击事件
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(link.href)
        link.remove()
      })
    },
    // 分页点击监听
    currentChange (index) {
      this.num = index
      this.getDevices()
    },
    // 修改设备
    modifyDevice () {
      this.$refs.modifyDeviceForm.validate((valid) => {
        if (valid) {
          this.$api.editDevices(this.modifyDeviceForm).then(({ data: res }) => {
            if (!res.code) {
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.modifyFormVisible = false
              this.getDevices()
            } else {
              this.$message({
                type: 'err',
                message: res.msg
              })
            }
          })
        }
      })
    },
    // 修改设备
    handleEdit (index, row) {
      this.modifyDeviceForm.sn = row.sn
      this.modifyDeviceForm.name = row.name
      this.modifyDeviceForm.note = row.note
      this.modifyFormVisible = true
    },
    // 删除设备
    handleDelete (index, row) {
      this.$confirm('是否删除 编号:' + row.sn + '， 名称：' + row.name)
        .then(_ => {
          this.$api.deleteDevices({
            sn: row.sn
          }).then(({ data: res }) => {
            if (!res.code) {
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.getDevices()
            } else {
              this.$message({
                type: 'err',
                message: res.msg
              })
            }
          })
        })
        .catch(_ => {})
    },
    // 保存二维码
    saveQrCode (index, row) {
      this.qrText = row.sn
    }
  }
}
</script>

<style>
.pageTitle {
  font-size: 25px;
  margin: 10px 0 10px 2.5%;
}

.page {
  width: 95%;
  height: 75%;
  margin: 0 2.5%;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}

.search {
  width: 100%;
  display: flex;
  margin-top: 20px;
}

.searchParam {
  width: 30%;
  display: flex;
  align-items: center;
}

.searchInput {
  width: 80%;
  margin-left: 10px;
}

.searchButton {
  margin-left: 10%;
}

.delete.el-button--mini {
  font-size: 12px;
  border-radius: 3px;
}

.delete.el-button--mini, .el-button--mini.is-round {
  padding: 4px 4px;
}
</style>
