<template>
  <div id="app">
    <router-view></router-view>
    <div style="position:fixed;text-align:center;bottom:0;margin:0 auto;width:100%;color: #5c6b77">
      <a target="_blank" style="color: #5c6b77" href="http://beian.miit.gov.cn">苏ICP备11020009号-2</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}

</script>

<style>

</style>
