import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
import './assets/css/global.css'
import axios from 'axios'// 引入axios进行地址访问
import store from './store'
import * as echarts from 'echarts'

import api from '@/common/request/api'

Vue.config.productionTip = false

Vue.use(ElementUI)

axios.defaults.baseURI = ''
Vue.prototype.$http = axios
// 引入echarts
Vue.prototype.$echarts = echarts

Vue.prototype.$api = api

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
