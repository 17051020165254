import request from '@/common/request/request'

const captcha = () => {
  return request.get('/auth/_/captcha')
}

const login = (params) => {
  return request.post('/auth/_/login', params)
}

const logout = () => {
  return request.get('/auth/_/logout')
}

const devices = (params, dn) => {
  return request.get('/user/query/with/devices', params, dn)
}

const devicesWithout = (params, dn) => {
  return request.get('/user/query/without/devices', params, dn)
}

const addDevices = (params) => {
  return request.post('/device/0/add', params)
}

const deleteDevices = (params) => {
  return request.post('/device/0/delete', params)
}

const editDevices = (params) => {
  return request.post('/device/0/edit', params)
}

const childUser = (params, dn) => {
  return request.get('/user/1/query/child', params, dn)
}

const addChildUser = (params, dn) => {
  return request.post('/user/1/add', params, dn)
}

const editChildUser = (params) => {
  return request.post('/user/1/edit', params)
}

const deleteChildUser = (params) => {
  return request.post('/user/1/delete', params)
}

const pushDevices = (params) => {
  return request.post('/user/1/push/devices', params)
}

const pullDevices = (params) => {
  return request.post('/user/1/pull/devices', params)
}

const resetPassword = (params) => {
  return request.post('/user/password', params)
}

const productWith = (params) => {
  return request.get('/user/query/with/products', params)
}

const queryDate = (params) => {
  return request.post('/data/_/query/date', params, { responseType: 'arraybuffer' })
}

export default {
  login,
  logout,
  captcha,
  devices,
  devicesWithout,
  addDevices,
  deleteDevices,
  editDevices,
  childUser,
  addChildUser,
  editChildUser,
  deleteChildUser,
  pushDevices,
  pullDevices,
  resetPassword,
  productWith,
  queryDate
}
